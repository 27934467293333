import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FullPageLayout from "../components/Layouts/FullPageLayout";
import { imgURL } from "../lib/assets";

const PrivacyPolicy = () => {
  return (
    <FullPageLayout>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Card
            style={{
              padding: "2em",
              textAlign: "center",
              margin: "1em 1em 2em",
            }}
          >
            <h1 style={{ margin: "0 0 1em", fontSize: "40px" }}>
              Privacy Policy
            </h1>
            <h3>
              This Privacy Policy describes Our policies and procedures on the
              collection, use and disclosure of Your information when You use
              the Service and tells You about Your privacy rights and how the
              law protects You.
              <br />
              <br />
              We use Your Personal data to provide and improve the Service. By
              using the Service, You agree to the collection and use of
              information in accordance with this Privacy Policy.
            </h3>
            <h2>
              Click{" "}
              <a
                href={imgURL("/ArvumPrivacyPolicy.pdf")}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "#aed581" }}
              >
                Here
              </a>{" "}
              to review our Policy.
            </h2>
          </Card>
        </Grid>
      </Grid>
    </FullPageLayout>
  );
};

export default PrivacyPolicy;
