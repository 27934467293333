import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FullPageLayout from "../components/Layouts/FullPageLayout";
import { imgURL } from "../lib/assets";

const TermsOfService = () => {
  return (
    <FullPageLayout>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Card
            style={{
              padding: "2em",
              textAlign: "center",
              margin: "1em 1em 2em",
            }}
          >
            <h1 style={{ margin: "0 0 1em", fontSize: "40px" }}>Terms of Service</h1>
            <h3>
              Please read these terms and conditions carefully before using Our
              Service.
            </h3>
            <h2>
              Click{" "}
              <a
                href={imgURL("/ArvumTermsOfService.pdf")}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "#aed581" }}
              >
                Here
              </a>{" "}
              to review our Terms.
            </h2>
          </Card>
        </Grid>
      </Grid>
    </FullPageLayout>
  );
};

export default TermsOfService;
